import { apiClient } from '../apiClient/apiClient';
import moment from 'moment';
import { db } from './db';
import { HistoricVesselVrRate, VesselVrRateDto } from '../apiClient/generated';
import { VesselVrRatesToDisplayTrendGraphData } from '../types/vesselVrRates';

export const fetchAndUpdateVesselVrRates = async () => {
    try {
        const vesselMetadata = await db.vesselMetadata.toArray();
        const vesselCompany = vesselMetadata[0]?.company;
        const fetchDate = moment().format('YYYY-MM');

        if (!vesselCompany) {
            console.error('Vessel company is not available');
            return;
        }

        const vrRatesResponse: VesselVrRateDto = await apiClient.getVesselVr(vesselCompany, fetchDate);

        if (vrRatesResponse) {
            await db.vesselVrRates.clear();
            await db.vesselVrRates.put(vrRatesResponse);
        }

        return vrRatesResponse;
    } catch (error) {
        console.error('Error fetching and updating vessel VR rates:', error);
    }
};

const getLast12Months = (): string[] => {
    const months = moment.monthsShort();
    const currentMonth = moment().month();
    return [...months.slice(currentMonth), ...months.slice(0, currentMonth)];
};

const getLast6Months = (): string[] => {
    const months = moment.monthsShort();
    const currentMonth = moment().month();

    return Array.from({ length: 6 }, (_, i) => months[(currentMonth - 6 + i + 12) % 12]);
};

const getMonthsForFilter = (filterType: 'last12Months' | 'yearToDate' | 'last6Months'): string[] => {
    if (filterType === 'last12Months') {
        return getLast12Months();
    } else if (filterType === 'last6Months') {
        return getLast6Months();
    } else {
        return moment.monthsShort();
    }
};

const isValidMonthForFilter = (item: HistoricVesselVrRate, month: string, filterType: 'last12Months' | 'yearToDate' | 'last6Months'): boolean => {
    const itemMonth = moment(item.month, 'YYYY-MM');
    const itemYear = itemMonth.year();
    const itemMonthIndex = itemMonth.month();
    const itemMonthFormatted = itemMonth.format('MMM').toLowerCase();

    const isSameMonth = month.toLowerCase() === itemMonthFormatted;

    const isLast12Months = filterType === 'last12Months' && itemYear === moment().year() - 1;
    const isYearToDate = filterType === 'yearToDate' && itemYear === moment().year();
    const isLast6Months = filterType === 'last6Months' && itemYear === moment().year() && itemMonthIndex >= (moment().month() - 6) && itemMonthIndex <= moment().month();

    return isSameMonth && (isLast12Months || isYearToDate || isLast6Months);
};

export const adjustVesselVrRatesToDisplayOnTrendGraph = (
    vrRatesHistory: HistoricVesselVrRate[] | null | undefined,
    filterType: 'last12Months' | 'yearToDate' | 'last6Months'
): VesselVrRatesToDisplayTrendGraphData[] => {
    if (!vrRatesHistory) {
        return [];
    }

    const months = getMonthsForFilter(filterType);

    return months.map((month) => {
        const vrRateForMonth = vrRatesHistory.find((item) => isValidMonthForFilter(item, month, filterType));

        return {
            month: month,
            vrRate: vrRateForMonth?.vrRate,
            budgetRate: vrRateForMonth?.budgetRate,
        };
    });
};

export const checkIfTrendDataIsEmpty = (
    trendData: VesselVrRatesToDisplayTrendGraphData[],
): boolean => {
    const isVrRateAvailable = trendData.some((item) => item.vrRate !== undefined);
    const isBudgetRateAvailable = trendData.some((item) => item.budgetRate !== undefined);
    return !isVrRateAvailable && !isBudgetRateAvailable;
};
